<template>
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15948 1.0003L1.56823 7.59154L7.36398 14.5237"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>
